import PropTypes from 'prop-types';
import {cnb} from 'cnbuilder';
import {Trans, useTranslation} from 'react-i18next';
import IconAlertDefault from '@teladoc/pulse/icons/alert-default.svg';
import Image from '@teladoc/pulse/ui/Image';
import cssNoMatch from '../../router/NoMatch.scss';
import ScreenReader from '../screen-reader/ScreenReader';
import CommonUtils from '../utilities/common-utils';
import Card from '../card/Card';
import UserAPI from '../../user/user-api';
import {NETWORK_STATUS} from '../../config';
import css from './ApiError.scss';

const ApiError = ({className}) => {
    const {t} = useTranslation();
    const networkStatus = UserAPI.getNetworkStatus(NETWORK_STATUS);

    return (
        <>
            {networkStatus && (
                <div className={cnb(css.root, className)}>
                    <IconAlertDefault className={css.icon} />
                    <div className={css.content}>
                        <h2 className="heading-sm" data-testid="heading">
                            {t('errors.api.title')}
                        </h2>
                        <p>
                            <Trans
                                i18nKey="app:errors.api.body"
                                components={[
                                    <a
                                        key={process.env.MEMBER_SUPPORT_EMAIL}
                                        href={`mailto:${process.env.MEMBER_SUPPORT_EMAIL}`}
                                    >
                                        <ScreenReader />
                                    </a>,
                                    <a
                                        key={process.env.MEMBER_SUPPORT_PHONE}
                                        href={`tel:${process.env.MEMBER_SUPPORT_PHONE}`}
                                    >
                                        <ScreenReader />
                                    </a>,
                                ]}
                                values={{
                                    email: process.env.MEMBER_SUPPORT_EMAIL,
                                    number: CommonUtils.formatPhoneNumber(
                                        process.env.MEMBER_SUPPORT_PHONE
                                    ),
                                }}
                            />
                        </p>
                    </div>
                </div>
            )}
            {!networkStatus && (
                <div className={cnb(cssNoMatch.root)}>
                    <Card className={css.card} fullExtension>
                        <Image
                            classNameRoot={cssNoMatch.image}
                            cloudinaryImageId="member-portal/common/error_tcmgli"
                            width={164}
                            height={117}
                            alt=""
                        />
                        <h1 className="heading-xs">
                            {t('errors.missing.title')}
                        </h1>
                        <p>{t('errors.missing.body')}</p>
                    </Card>
                </div>
            )}
        </>
    );
};

ApiError.propTypes = {
    className: PropTypes.string,
};

export default ApiError;
